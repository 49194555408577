import { Link } from "gatsby";
import React from "react";

import GameonLogoColor from "../../../../static/svg/gameon-logo-color.svg";
import LayoutSlack from "../../../components/layout-slack/layout-slack";
import Seo from "../../../components/seo/seo";

import "./error.scss";

const ErrorPage = () => (
  <div className="slack-error-page white-header-bg">
    <LayoutSlack>
      <Link to="/">
        <GameonLogoColor />
      </Link>
      <div className="error-msg">
        <h1 className="error-heading">Whoops...</h1>
        <p className="error-text">Something went wrong.</p>
        <p className="contact-text">
          Please{" "}
          <Link className="underline--inline" to="/contact">
            contact us
          </Link>{" "}
          for help.
        </p>
      </div>
    </LayoutSlack>
  </div>
);

export const Head = ({ location }) => {
  const seoMeta = {
    metaTitle: "Error!",
    locale: "en-US",
    slug: location.pathname,
  };
  return <Seo {...seoMeta} />;
};

export default ErrorPage;
